import React from 'react';

const Home = () => {
    
    return (
        <div className="auth-inner">
            <h5>Welcome to Login/SignUp Demo React App</h5>
            <p> Click on Login/SignUp Button to test frontend.</p>
        </div>
    );
}

export default Home;